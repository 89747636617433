<template lang="pug">
.container-fluid.px-3
  .row.mb-4
    .col
      om-heading.mt-1(h1) {{ $t('personalDetails') }}
  .row
    .col-5.col-lg-3.mt-1.position-static
      form.d-flex.flex-column
        template
          .form-group.row.order-2
            .col-12
              om-input#p_fname(
                v-model="firstName"
                type="text"
                placeholder="e.g. John"
                :label="$t('firstName')"
              )
                template(slot="error" v-if="$v.firstName.$error && !$v.firstName.required")
                  span {{ $t('requiredField') }}
                template(slot="error" v-if="$v.firstName.$error && !$v.firstName.notUrl")
                  span {{ $t('notValidFormat') }}
                template(slot="error" v-if="$v.firstName.$error && !$v.firstName.maxLength")
                  span {{ $t('firstNameTooLong', { length: $v.firstName.$params.maxLength.max }) }}
          .form-group.row(:class="isEn ? 'order-2' : 'order-1'")
            .col-12
              om-input#p_lname(
                v-model="lastName"
                type="text"
                placeholder="e.g. Appleseed"
                :label="$t('lastName')"
              )
                template(slot="error" v-if="$v.lastName.$error && !$v.lastName.required")
                  span {{ $t('requiredField') }}
                template(slot="error" v-if="$v.lastName.$error && !$v.lastName.notUrl")
                  span {{ $t('notValidFormat') }}
                template(slot="error" v-if="$v.lastName.$error && !$v.lastName.maxLength")
                  span {{ $t('lastNameTooLong', { length: $v.lastName.$params.maxLength.max }) }}
        .form-group.row.mb-4.order-2
          label.col-12.mb-1.font-size-0--9375 {{ $t('locale') }}
          .col-12
            toggle-button.mb-0.locale-toggle(
              :value="locale === 'hu'"
              :sync="true"
              :labels="{ checked: 'HU', unchecked: 'EN' }"
              :width="50"
              :height="24"
              @change.self="locale = $event.value ? 'hu' : 'en'"
            )
        .form-group.row.order-2
          .col-12
            om-button(primary type="submit" @click.prevent="save()") {{ $t('save') }}
</template>

<script>
  import GET_ACCOUNT from '@/graphql/GetAccount.gql';
  import UPDATE_LOGIN from '@/graphql/UpdateLogin.gql';
  import { required, maxLength } from 'vuelidate/lib/validators';
  import { validateDomain } from '@/util';
  import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        firstName: '',
        lastName: '',
        locale: '',
        origLocale: '',
        // email: ''
      };
    },

    validations: {
      firstName: {
        required,
        notUrl(v) {
          return !validateDomain(v);
        },
        maxLength: maxLength(30),
      },
      lastName: {
        required,
        notUrl(v) {
          return !validateDomain(v);
        },
        maxLength: maxLength(30),
      },
    },

    computed: {
      isEn() {
        return this.getRegion() !== 'Hungary';
      },
    },

    mounted() {
      this.$apollo.query({ query: GET_ACCOUNT }).then(({ data }) => {
        this.firstName = data.account.login.firstName;
        this.lastName = data.account.login.lastName;
        this.origLocale = data.account.login.locale;
        this.locale = data.account.login.locale;
      });
    },

    methods: {
      ...mapGetters(['getRegion']),
      save() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }

        this.$apollo
          .mutate({
            mutation: UPDATE_LOGIN,
            variables: {
              input: {
                firstName: this.firstName,
                lastName: this.lastName,
                locale: this.locale,
              },
            },
            refetchQueries: ['GetAccount'],
          })
          .then(() => {
            this.$notify({
              type: 'success',
              title: this.$t('notifications.saveSuccess'),
            });

            if (this.origLocale !== this.locale) {
              window.location.reload();
            }
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: this.$t('notifications.saveError'),
            });
          });
      },
    },
  };
</script>

<style lang="sass">
  .vue-js-switch.locale-toggle
    .v-switch-button
      background-image: url('~@/assets/admin/img/en.svg')
      background-color: #fff
      background-size: cover
      background-position: center center
      z-index: 1

    // .v-switch-label.v-right, .v-switch-label.v-left
    //   color: #2cc896 !important

    .v-switch-label.v-left
      color: white !important

    &.toggled
      border: 1px solid #D5D8DD
      .v-switch-button
        background-image: url('~@/assets/admin/img/hu.svg')
      .v-switch-label.v-left
        color: #9EA2A6 !important
      .v-switch-core
        background-color: #F1F2F4 !important
</style>
